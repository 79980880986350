import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  .p-section-inner {
    display: flex;
    max-width: 1080px;
    padding: 60px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    .p-section-download {
      width: 100%;
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  }
  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-download {
      }
    }
  }
`
/**
 * サービスサイト資料ダウンロード-入力後画面
 * @constructor
 */
const OfficialLowDownload02 = () => (
  <Wrapper className="done">
    <div className="p-section-inner">
      <OfficialCommonH3Set
        label="お申し込みありがとうございました"
        description={
          '弊社の資料に興味を持ってくださりありがとうございます。\nご入力いただいたメールアドレス宛に、ダウンロード用URLを送信いたしました。'
        }
      />
      <div className="p-section-download">
        <img
          src="/images/official/download/imgSendMail.png"
          alt="メールを送信しました"
        />
      </div>
    </div>
  </Wrapper>
)

export default OfficialLowDownload02
