import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import BreakPoints from '../../styles/breakPoints'
import ScrollTop from '../atoms/scrollTop'
import TopicPath, { ITopicPath } from '../atoms/topicPath'
import OfficialFooter from './officialFooter'
import OfficialHeader from './officialHeader'

const Wrapper = styled.article`
  font-size: 16px;

  ${BreakPoints.small} {
  }
`

type Props = {
  /** パンくずパス */
  paths?: ITopicPath[]
  /** ページ内容 */
  children: ReactNode
}

/**
 * サービスサイト問い合わせ用レイアウト
 * @constructor
 */
const OfficialContactLayout = ({ paths, children }: Props) => (
  <div id="pageTop">
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
      />
      <script>
        {`
            function showElementAnimation() {

              var element = document.querySelectorAll('.anim-fade-up');
              if(!element) return;

              var showTiming = window.innerHeight > 768 ? 120 : 40;
              var scrollY = window.pageYOffset;
              var windowH = window.innerHeight;

              for(var i=0;i<element.length;i++) { var elemClientRect = element[i].getBoundingClientRect(); var elemY = scrollY + elemClientRect.top; if(scrollY + windowH - showTiming > elemY) {
                element[i].classList.add('is-show');
              }
            }
          }
          showElementAnimation();
          window.addEventListener('scroll', showElementAnimation);
        `}
      </script>
    </Helmet>
    <OfficialHeader />
    <noscript>Javascriptが無効なため有効にしてください</noscript>
    <Wrapper>
      {paths && <TopicPath paths={paths} />}
      {children}
    </Wrapper>
    <OfficialFooter />
    <ScrollTop />
  </div>
)

export default OfficialContactLayout
